<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col>
          <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" class="mtrTab">
            <el-table-column label="成品采购号" prop="podr_no"/>
            <el-table-column label="供应商简称" prop="supp_abbr"/>
            <el-table-column label="变更次数" prop="chan_num">
              <template slot-scope="scope">
                <span v-if="scope.row.chan_num > 0 && scope.row.chan_status === 1">
                  <el-badge :is-dot="scope.row.chan_status === 1" class="item">
                    <span>{{scope.row.chan_num}}</span>
                  </el-badge>
                </span>
                <span v-else-if="scope.row.chan_num > 0 && scope.row.chan_status === 0">
                  <span style="color: #000;font-weight: bold">{{scope.row.chan_num}}</span>
                </span>
                <span v-else>
                  <span style="color: #0e932e">{{scope.row.chan_num}}</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="采购经办人" prop="popu_stff_name"></el-table-column>
            <el-table-column label="下发状态" prop="popu_status" min-width="50" align="center">
							<template v-slot="scope">
								<el-tag v-if="scope.row.popu_status === 0"  type="info"	size="small" >未下发</el-tag>
								<el-tag v-if="scope.row.popu_status === 1" type="warning"	size="small" >部分失效</el-tag>
								<el-tag v-else-if="scope.row.popu_status === 2"  size="small">已下发</el-tag>
								<el-tag v-else-if="scope.row.popu_status === 3"  type="success"	size="small">已完结</el-tag>
								<el-tag v-else-if="scope.row.popu_status === 4"  type="danger"	size="small">已失效</el-tag>
							</template>
						</el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import { matrAPI } from "@api/modules/matr";
import helper from "@assets/js/helper.js"
import { popuAPI } from "../../../api/modules/popu";
export default {
  name: "getPropucaByChange",
  data(){
    return{
      tableData: [],
      loading: true,
    }
  },
  created() {
    this.initData()
  },
  mounted() {
  },
  methods: {
    initData() {
      this.loading = true;
      this.getPushList()
    },
    //获取信息
    getPushList(){
      get(popuAPI.getPropucaByChange)
          .then(res=>{
            if(res.data.code === 0){
              this.tableData = res.data.data.list
              setTimeout(() => {
                this.loading = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    buttonRefresh(){
      this.initData()
    }
  }
}
</script>

<style scoped lang="scss">
.vd_completed{
  color: $color-secondary-text;
}
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.mtrTab {
  margin-top:10px ;
  cursor: pointer;
}
</style>